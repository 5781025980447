@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";
.path-event {
  .article {
    max-width: 1000px;
    margin: 20px auto 80px;
    .event-tab {
      margin-bottom: 80px;
      .tab-nav {
        display: flex;
        border-bottom: 4px solid $COLOR_MAIN;
        font-size: clamp(16px,3vw,24px);
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 1.25;
        &__item {
          flex: 1 1 50%;
          display: flex;
          align-items: center;
          background: $COLOR_SUB;
          text-align: center;
          &.current {
            background: $COLOR_MAIN;
            a {
              color: $GRAY_000;
              &::after {
                border-right: 3px solid $GRAY_000;
                border-bottom: 2.5px solid $GRAY_000;
              }
            }
          }
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: clamp(8px,2vw,16px);
            &::after {
              content: "";
              display: block;
              width: 21px;
              height: 10px;
              border-right: 3px solid $COLOR_MAIN;
              border-bottom: 2.5px solid $COLOR_MAIN;
              transform: skew(45deg, 0);
              margin-top: 8px;
              margin-left: 16px;
            }
          }
        }
      }
      .tab-select {
        background: $GRAY_100;
        font-size: clamp(16px,3vw,20px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0;
        .txt {
          margin-right: 16px;
        }
        select {
          /* 初期化 */
          appearance: none;
          background: none;
          border: none;
          font-size: clamp(16px,3vw,20px);
          font-weight: 700;
          text-align: center;
          width: 100%;
          height: 100%;
          padding: 0 10px;
        }
        .select-btn {
          background: $GRAY_000;
          height: 40px;
          width: clamp(200px,22vw,260px);
          border: 2px solid $GRAY_700;
          position: relative;
          z-index: 1;
          &::before {
            position: absolute;
            content: '';
            background: url(/img/common/ico_search.svg) left top no-repeat;
            width: clamp(20px,3vw,26px);
            height: clamp(18px,3vw,24px);
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
          }
          &::after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            right: 10px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            border-bottom: 2px solid $GRAY_700;
            border-right: 2px solid $GRAY_700;
            z-index: -1;
          }
        }

      }
    }
    .evnt-wrap {
      padding: 0 8px;
      margin-bottom: 40px;
      .article-title {
        color: $GRAY_700;
        font-size: clamp(18px,3vw,24px);
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 1;
        margin: 0 0 12px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: -20px;
          left: -20px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 6px solid $GRAY_100;
          z-index: -1;
        }
      }
      .article-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__item {
          background: $GRAY_100;
          a {
            display: flex;
            padding: 8px;
            gap: 8px;
            color: $GRAY_700;
            figure {
              align-self: flex-start;
              flex: 0 0 140px;
              overflow: hidden;
              img {
                object-fit: cover;
                width: 100%;
                height: 98px;
                vertical-align: bottom;
              }
            }
            .item-block {
              font-size: clamp(14px,2vw,16px);
              .item-schedule {
                margin-top: 4px;
                font-size: 14px;
                font-weight: 700;
                &::before {
                  content: "";
                  display: inline-block;
                  background: url(/img/top/topics_schedule.svg) top left  no-repeat;
                  width: 17px;
                  padding-top: 18px;
                  margin-bottom: -3px;
                  margin-right: 4px;
                }
              }
              .item-artist {
                margin-top: 4px;
                font-weight: 700;
              }
              .item-txt {
                margin-top: 4px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}






/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
  .path-event {
    .article {
      .event-tab {
        .tab-select {
          .select-btn {
            height: 50px;
          }
        }
      }
      .evnt-wrap {
        padding: 0 0;
        margin-bottom: 80px;
        .article-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
          &__item {
            flex: 0 0 490px;
            a {
              display: flex;
              gap: 24px;
              color: $GRAY_700;
              figure {
                flex: 0 0 200px;
                img {
                  height: 152px;
                }
              }
              .item-block {
                .item-schedule {
                  margin-top: 8px;
                  font-size: 16px;
                }
                .item-artist {
                  margin-top: 8px;
                }
                .item-txt {
                  margin-top: 8px;
                }
              }
              &:hover {
                figure {
                  img {
                    filter: brightness(70%);
                    transform: scale(1.1,1.1);
                  }
                }
                .item-txt {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
