@charset "UTF-8";
/*====================================
	1. Color
====================================*/
.path-event .article {
  max-width: 1000px;
  margin: 20px auto 80px;
}

.path-event .article .event-tab {
  margin-bottom: 80px;
}

.path-event .article .event-tab .tab-nav {
  display: flex;
  border-bottom: 4px solid #001976;
  font-size: clamp(16px, 3vw, 24px);
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.25;
}

.path-event .article .event-tab .tab-nav__item {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  background: #E5EAFF;
  text-align: center;
}

.path-event .article .event-tab .tab-nav__item.current {
  background: #001976;
}

.path-event .article .event-tab .tab-nav__item.current a {
  color: #FFFFFF;
}

.path-event .article .event-tab .tab-nav__item.current a::after {
  border-right: 3px solid #FFFFFF;
  border-bottom: 2.5px solid #FFFFFF;
}

.path-event .article .event-tab .tab-nav__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: clamp(8px, 2vw, 16px);
}

.path-event .article .event-tab .tab-nav__item a::after {
  content: "";
  display: block;
  width: 21px;
  height: 10px;
  border-right: 3px solid #001976;
  border-bottom: 2.5px solid #001976;
  transform: skew(45deg, 0);
  margin-top: 8px;
  margin-left: 16px;
}

.path-event .article .event-tab .tab-select {
  background: #F5F5F5;
  font-size: clamp(16px, 3vw, 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}

.path-event .article .event-tab .tab-select .txt {
  margin-right: 16px;
}

.path-event .article .event-tab .tab-select select {
  /* 初期化 */
  appearance: none;
  background: none;
  border: none;
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 700;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

.path-event .article .event-tab .tab-select .select-btn {
  background: #FFFFFF;
  height: 40px;
  width: clamp(200px, 22vw, 260px);
  border: 2px solid #121212;
  position: relative;
  z-index: 1;
}

.path-event .article .event-tab .tab-select .select-btn::before {
  position: absolute;
  content: '';
  background: url(/img/common/ico_search.svg) left top no-repeat;
  width: clamp(20px, 3vw, 26px);
  height: clamp(18px, 3vw, 24px);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.path-event .article .event-tab .tab-select .select-btn::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border-bottom: 2px solid #121212;
  border-right: 2px solid #121212;
  z-index: -1;
}

.path-event .article .evnt-wrap {
  padding: 0 8px;
  margin-bottom: 40px;
}

.path-event .article .evnt-wrap .article-title {
  color: #121212;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1;
  margin: 0 0 12px;
  position: relative;
}

.path-event .article .evnt-wrap .article-title::after {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6px solid #F5F5F5;
  z-index: -1;
}

.path-event .article .evnt-wrap .article-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.path-event .article .evnt-wrap .article-list__item {
  background: #F5F5F5;
}

.path-event .article .evnt-wrap .article-list__item a {
  display: flex;
  padding: 8px;
  gap: 8px;
  color: #121212;
}

.path-event .article .evnt-wrap .article-list__item a figure {
  align-self: flex-start;
  flex: 0 0 140px;
  overflow: hidden;
}

.path-event .article .evnt-wrap .article-list__item a figure img {
  object-fit: cover;
  width: 100%;
  height: 98px;
  vertical-align: bottom;
}

.path-event .article .evnt-wrap .article-list__item a .item-block {
  font-size: clamp(14px, 2vw, 16px);
}

.path-event .article .evnt-wrap .article-list__item a .item-block .item-schedule {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 700;
}

.path-event .article .evnt-wrap .article-list__item a .item-block .item-schedule::before {
  content: "";
  display: inline-block;
  background: url(/img/top/topics_schedule.svg) top left no-repeat;
  width: 17px;
  padding-top: 18px;
  margin-bottom: -3px;
  margin-right: 4px;
}

.path-event .article .evnt-wrap .article-list__item a .item-block .item-artist {
  margin-top: 4px;
  font-weight: 700;
}

.path-event .article .evnt-wrap .article-list__item a .item-block .item-txt {
  margin-top: 4px;
  font-weight: 400;
}

/****************************************************
 PC scss
****************************************************/
@media screen and (min-width: 768px) {
  .path-event .article .event-tab .tab-select .select-btn {
    height: 50px;
  }
  .path-event .article .evnt-wrap {
    padding: 0 0;
    margin-bottom: 80px;
  }
  .path-event .article .evnt-wrap .article-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  .path-event .article .evnt-wrap .article-list__item {
    flex: 0 0 490px;
  }
  .path-event .article .evnt-wrap .article-list__item a {
    display: flex;
    gap: 24px;
    color: #121212;
  }
  .path-event .article .evnt-wrap .article-list__item a figure {
    flex: 0 0 200px;
  }
  .path-event .article .evnt-wrap .article-list__item a figure img {
    height: 152px;
  }
  .path-event .article .evnt-wrap .article-list__item a .item-block .item-schedule {
    margin-top: 8px;
    font-size: 16px;
  }
  .path-event .article .evnt-wrap .article-list__item a .item-block .item-artist {
    margin-top: 8px;
  }
  .path-event .article .evnt-wrap .article-list__item a .item-block .item-txt {
    margin-top: 8px;
  }
  .path-event .article .evnt-wrap .article-list__item a:hover figure img {
    filter: brightness(70%);
    transform: scale(1.1, 1.1);
  }
  .path-event .article .evnt-wrap .article-list__item a:hover .item-txt {
    text-decoration: underline;
  }
}
